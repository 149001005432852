import router from '@/router'
// eslint-disable-next-line object-curly-newline
import { reactive, getCurrentInstance, watch, toRefs } from '@vue/composition-api'
import moment from 'moment'

export const getFullName = data => {
  let fullName = ''

  if (data) {
    if (data.middleName && data.middleName.length > 0) {
      fullName = `${data.firstName} ${data.middleName} ${data.lastName}`
    } else {
      fullName = `${data.firstName} ${data.lastName}`
    }
  }
  return fullName
}

export const formatDate = date => moment(date).format('DD-MM-YYYY')

export const formatDateTime = date => {
  if (!date || date === '') {
    return ''
  }

  return moment(date).format('DD-MM-YYYY hh:mm:ss')
}

export const todayMinYears = years => {
  const date = new Date()
  date.setFullYear(date.getFullYear() - years)

  const dd = date.getDate()
  const mm = date.getMonth() + 1
  const yyyy = date.getFullYear()

  return `${yyyy}-${mm}-${dd}`
}

export const isOlderThenEighteen = date => date > todayMinYears(18)
export const dateMaxToday = date => date > new Date()
export const dateMinToday = date => date < new Date()

export const todayPlusYears = years => {
  const date = new Date()
  date.setFullYear(date.getFullYear() + years)
  return date
}

export const removeFromArray = data => {
  const dataArray = data.array
  dataArray.forEach((element, index) => {
    if (element[data.key] === data.value) dataArray.splice(index, 1)
  })

  return dataArray
}

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

const getRandomFromArray = array => array[Math.floor(Math.random() * array.length)]

// ? Light and Dark variant is not included
// prettier-ignore
export const getRandomBsVariant = () => getRandomFromArray(['primary', 'secondary', 'success', 'warning', 'danger', 'info'])

export const isDynamicRouteActive = route => {
  const { route: resolvedRoute } = router.resolve(route)
  return resolvedRoute.path === router.currentRoute.path
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy
  const state = reactive({
    route: vm.$route,
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    },
  )

  return { ...toRefs(state), router: vm.$router }
}

export const generatePassword = () => {
  let CharacterSet = ''
  let password = ''
  const size = 12
  const charactersArray = 'a-z,A-Z,0-9,#'.split(',')

  if (charactersArray.indexOf('a-z') >= 0) {
    CharacterSet += 'abcdefghijklmnopqrstuvwxyz'
  }
  if (charactersArray.indexOf('A-Z') >= 0) {
    CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  }

  if (charactersArray.indexOf('0-9') >= 0) {
    CharacterSet += '0123456789'
  }
  if (charactersArray.indexOf('#') >= 0) {
    CharacterSet += '![]{}()%&*$#<>@|+=?S'
  }
  for (let i = 0; i < size; i += 1) {
    password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length))
  }

  return password
}

/**
 * This is just enhancement over Object.extend [Gives deep extend]
 * @param {target} a Object which contains values to be overridden
 * @param {source} b Object which contains values to override
 */
// export const objectExtend = (a, b) => {
//   // Don't touch 'null' or 'undefined' objects.
//   if (a == null || b == null) {
//     return a
//   }

//   Object.keys(b).forEach(key => {
//     if (Object.prototype.toString.call(b[key]) === '[object Object]') {
//       if (Object.prototype.toString.call(a[key]) !== '[object Object]') {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = b[key]
//       } else {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = objectExtend(a[key], b[key])
//       }
//     } else {
//       // eslint-disable-next-line no-param-reassign
//       a[key] = b[key]
//     }
//   })

//   return a
// }
